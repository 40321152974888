import { getText } from 'assets/localization/localization';
import { FunctionComponent, PropsWithChildren } from 'react';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';
import { pharmacySoftwares } from '../../../../common/integrations-utils';

export const PMSConfigDetailsItemRenderer: FunctionComponent<
  PropsWithChildren<PMSConfigDetailsItemRendererProps>
> = ({
  integration_type,
  server_address,
  server_port,
  auth_user,
  auth_secret,
  npi,
  api_key,
}) => {
  const selectedPMS = pharmacySoftwares.find(
    (software) => software.value === integration_type,
  );

  return (
    <>
      <RowItemRender
        title={getText('pharmacy-software')}
        value={selectedPMS?.label ?? ''}
      />
      <RowItemRender
        title={getText('server-address')}
        value={server_address ?? ''}
      />
      {selectedPMS?.fields.includes('npi') && npi && (
        <RowItemRender title={getText('pharmacy-npi-number')} value={npi} />
      )}
      {selectedPMS?.fields.includes('api_key') && api_key && (
        <RowItemRender
          title={getText('api-key')}
          value={api_key}
          isSecretValue={true}
        />
      )}
      {selectedPMS?.fields.includes('server_port') && server_port && (
        <RowItemRender title={getText('server-port')} value={server_port} />
      )}
      {selectedPMS?.fields.includes('auth_user') && auth_user && (
        <RowItemRender title={getText('username')} value={auth_user} />
      )}
      {selectedPMS?.fields.includes('auth_secret') && auth_secret && (
        <RowItemRender
          title={getText('password')}
          value={auth_secret}
          isSecretValue={true}
        />
      )}
    </>
  );
};

export interface PMSConfigDetailsItemRendererProps {
  integration_type?: string;
  server_address?: string;
  server_port?: string;
  auth_user?: string;
  auth_secret?: string;
  npi?: string;
  api_key?: string;
}
