import {
  CreateDepartmentDto,
  CreateNormalTimeRangeDto,
  UpdateDepartmentDto,
  UpdatePharmacyDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { logError } from 'assets/logging/logger';
import PMSIntegrationService from '../../../api/PMSIntegrationService';
import PharmacyService from '../../../api/PharmacyService';
import {
  CountryCode,
  DepartmentHours,
  usePharmacySettingsState as pharmacyState,
} from './pharmacy-settings-store';

export const setShowModalGeneralInfo = (showModalGeneralInfo: boolean) =>
  pharmacyState.setState({ showModalGeneralInfo });
export const setShowModalContactInfo = (showModalContactInfo: boolean) =>
  pharmacyState.setState({ showModalContactInfo });
export const setShowModalDepartmentHours = (
  showModalDepartmentHours: boolean,
) => {
  pharmacyState.setState({ showModalDepartmentHours });
};
export const setEditingDepartmentId = (editingDepartmentId?: string) => {
  pharmacyState.setState({ editingDepartmentId });
};
export const setShowModalDepartment = (
  showModalDepartment: boolean,
  editingDepartmentId?: string,
) => {
  pharmacyState.setState({ showModalDepartment, editingDepartmentId });
  getEditingDepartment();
};
export const setPharmacy = async (pharmacy: UpdatePharmacyDto) => {
  const { pharmacyId } = pharmacyState.getState();
  if (pharmacyId) {
    await PharmacyService.updatePharmacyRecord(pharmacyId, pharmacy);
    pharmacyState.setState((prevState) => ({ ...prevState, ...pharmacy }));
  }
};
export const updateDepartment = async (
  editingDepartment: UpdateDepartmentDto,
  departmentId?: string,
) => {
  const { pharmacyId, editingDepartmentId } = pharmacyState.getState();
  departmentId = departmentId || editingDepartmentId;
  if (pharmacyId && departmentId) {
    await PharmacyService.updatePharmacyDepartment(
      pharmacyId,
      departmentId,
      editingDepartment,
    ).then(() =>
      pharmacyState.setState((prevState) => ({
        ...prevState,
        ...editingDepartment,
      })),
    );
  }
};
export const createDepartment = async (department: CreateDepartmentDto) => {
  const { pharmacyId } = pharmacyState.getState();
  if (pharmacyId) {
    await PharmacyService.createPharmacyDepartment(pharmacyId, department);
  }
};
export const removeDepartment = async (departmentId?: string) => {
  const { pharmacyId, editingDepartmentId } = pharmacyState.getState();
  departmentId = departmentId || editingDepartmentId;
  if (pharmacyId && departmentId) {
    await PharmacyService.removePharmacyDepartment(
      pharmacyId,
      departmentId,
    ).then(async () => {
      await getDepartments();
      const editingDepartmentId = undefined;
      const editingDepartment = undefined;
      pharmacyState.setState({ editingDepartmentId, editingDepartment });
    });
  }
};
export const setCountry = (country: CountryCode) =>
  pharmacyState.setState({ country });
export const setPharmacyId = async (pharmacyId: string) => {
  pharmacyState.setState({ pharmacyId });
  await getPharmacy();
};
export const getPharmacy = async () => {
  const { pharmacyId } = pharmacyState.getState();
  if (!pharmacyId) {
    pharmacyState.setState({ pharmacy: undefined });
    return;
  }
  await PharmacyService.pharmacyGetById(pharmacyId).then((pharmacy) =>
    pharmacyState.setState(() => ({ pharmacy })),
  );
};
export const createDepartmentHours = async (
  timeRange: CreateNormalTimeRangeDto[],
  departmentName?: string,
) => {
  const { editingDepartmentId, pharmacyId } = pharmacyState.getState();
  if (editingDepartmentId) {
    await PharmacyService.createDepartmentHours(editingDepartmentId, timeRange);
  } else if (departmentName && pharmacyId) {
    await PharmacyService.createPharmacyDepartment(pharmacyId, {
      name: departmentName,
    })
      .then((department) =>
        PharmacyService.createDepartmentHours(department.id, timeRange),
      )
      .catch((error: Error) => logError(error));
  }
};
export const getDepartmentsHours = async () => {
  const { departments } = pharmacyState.getState();
  const newDepartmentsHours: DepartmentHours[] = await Promise.all(
    departments.map(async (department) => {
      return {
        departmentId: department.id,
        departmentName: department.name,
        departmentHours: await PharmacyService.getHoursByDepartment(
          department.id,
        ),
      };
    }),
  );
  setDepartmentsHours(newDepartmentsHours);
};
export const getEditingDepartmentHours = () => {
  const { departmentsHours, editingDepartmentId } = pharmacyState.getState();
  const editingDepartmentHours = editingDepartmentId
    ? departmentsHours.find(
        (department) => department.departmentId === editingDepartmentId,
      )
    : undefined;
  pharmacyState.setState({ editingDepartmentHours });
};
export const setDepartmentsHours = (departmentsHours: DepartmentHours[]) =>
  pharmacyState.setState({ departmentsHours });
export const getDepartments = async () => {
  const { pharmacyId } = pharmacyState.getState();
  if (!pharmacyId) {
    pharmacyState.setState({ departments: [] });
    return;
  }
  await PharmacyService.departmentGetByPharmacy(pharmacyId).then(
    (departments) => pharmacyState.setState(() => ({ departments })),
  );
};
export const getEditingDepartment = () => {
  const { editingDepartmentId, departments } = pharmacyState.getState();
  const editingDepartment = editingDepartmentId
    ? departments.find((dep) => dep.id == editingDepartmentId)
    : undefined;
  pharmacyState.setState({ editingDepartment });
};

export const getAllPMS = () => {
  void PMSIntegrationService.pmsFindAll()
    .then((allPMS) => pharmacyState.setState({ allPMS }))
    .catch((error: Error) => logError(error));
};
