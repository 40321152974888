import {
  DepartmentDto,
  NormalTimeRangeDto,
  PharmacyDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { PmsDto } from '@digitalpharmacist/pms-integration-service-client-axios';
import create from 'zustand';
import { useAppStateStore } from '../../../store/app-store';
import { AsyncStatus } from '../../../store/types';

export enum CountryCode {
  US = 'US',
  CA = 'CA',
}

export enum DaysOfWeekEnum {
  Sunday = 1,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export interface DepartmentHours {
  departmentId: string;
  departmentName: string;
  departmentHours: NormalTimeRangeDto[];
}

export interface PharmacySettingsState {
  showModalGeneralInfo: boolean;
  showModalContactInfo: boolean;
  showModalDepartmentHours: boolean;
  showModalDepartment: boolean;
  pharmacy?: PharmacyDto;
  status: AsyncStatus;
  editingGeneralInfo: boolean;
  editGeneralInfoId?: string;
  pharmacyId?: string;
  country: CountryCode;
  departmentsHours: DepartmentHours[];
  editingDepartmentHours?: DepartmentHours;
  departments: DepartmentDto[];
  editingDepartmentId?: string;
  editingDepartment?: DepartmentDto;
  allPMS: PmsDto[];
}

export const usePharmacySettingsState = create<PharmacySettingsState>(() => ({
  showModalGeneralInfo: false,
  showModalContactInfo: false,
  showModalDepartmentHours: false,
  showModalDepartment: false,
  status: 'idle',
  editingGeneralInfo: false,
  pharmacyId: useAppStateStore.getState().pharmacyId,
  country: CountryCode.US,
  departments: [],
  departmentsHours: [],
  allPMS: [],
}));
