import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'assets/form';
import { Modal } from 'assets/components/modal';
import { makeStyles } from 'assets/theme';
import { usePMSIntegrationsState } from '../pms-integration-settings-store';
import { updateShowPMSModalState } from '../pms-integrations-settings-actions';
import { Form } from 'assets/layout/form/Form';
import { TextField } from 'assets/components/text-field';
import { getText } from 'assets/localization/localization';
import { PmsConfigDto } from '@digitalpharmacist/pms-integration-service-client-axios';
import { createOrUpdatePMSConfig } from '../pms-integrations-settings-actions';
import { useAppStateStore } from '../../../../store/app-store';
import { DropdownSelect } from 'assets/components/dropdown-select/DropdownSelect';
import { View } from 'react-native';
import { pharmacySoftwares } from '../../../../common/integrations-utils';

export interface PMSListOption {
  value: string;
  label: string;
}

export type PMSForm = PmsConfigDto;

const initialFormValue: PmsConfigDto = {
  integration_type: '',
  server_address: '',
  server_port: null,
  timeout: '',
  allowed_concurrency: '',
  auth_url: '',
  auth_user: '',
  auth_secret: '',
  multistore_enable: false,
  multistore_offset: '',
  multistore_length: '',
  npi: '',
  pharmacy_phone: '',
  token: '',
  api_host: '',
  subscription_key: '',
  api_key: '',
};

export const PMSIntegrationFormModal: FunctionComponent<
  PropsWithChildren<PMSIntegrationFormProps>
> = () => {
  const { showPMSFormModal } = usePMSIntegrationsState();

  const styles = useStyles();

  const { pmsConfig } = usePMSIntegrationsState();
  const { locationId } = useAppStateStore();

  const [fieldsPerPMS, setFieldsPerPMS] = useState<string[]>([]);

  const methods = useForm<PMSForm>({
    defaultValues: initialFormValue,
    mode: 'onChange',
  });

  const selectedPMS = methods.watch('integration_type');

  useEffect(() => {
    if (selectedPMS !== '' && selectedPMS !== pmsConfig.integration_type) {
      const emptyForm = { ...initialFormValue };
      emptyForm.integration_type = selectedPMS;
      methods.reset(emptyForm);
    }

    const selectedPMSData = pharmacySoftwares.find(
      (software) => software.value === selectedPMS,
    );
    if (selectedPMSData) {
      setFieldsPerPMS(selectedPMSData.fields);
    }
  }, [selectedPMS]);

  useEffect(() => {
    if (showPMSFormModal == true) {
      resetForm();
    }
  }, [pmsConfig, showPMSFormModal]);

  const resetForm = () => {
    methods.reset({
      integration_type: pmsConfig.integration_type,
      server_address: pmsConfig.server_address,
      server_port: pmsConfig.server_port ?? 0,
      timeout: pmsConfig.timeout ?? '',
      allowed_concurrency: pmsConfig.allowed_concurrency ?? '',
      auth_url: pmsConfig.auth_url ?? '',
      auth_user: pmsConfig.auth_user ?? '',
      auth_secret: pmsConfig.auth_secret ?? '',
      multistore_enable: pmsConfig.multistore_enable ?? false,
      multistore_offset: pmsConfig.multistore_offset ?? '',
      multistore_length: pmsConfig.multistore_length ?? '',
      npi: pmsConfig.npi ?? '',
      pharmacy_phone: pmsConfig.pharmacy_phone ?? '',
      token: pmsConfig.token ?? '',
      api_host: pmsConfig.api_host ?? '',
      subscription_key: pmsConfig.subscription_key ?? '',
      api_key: pmsConfig.api_key ?? '',
    });
  };

  const handleSubmit = async () => {
    if (!fieldsPerPMS.includes('server_port')) {
      methods.setValue('server_port', null);
    }
    await createOrUpdatePMSConfig(locationId, methods.getValues());
    closeModal();
  };

  const closeModal = () => {
    updateShowPMSModalState(false);
  };

  return (
    <>
      <Modal
        title={getText('edit-integration')}
        titleSize="sm"
        dismissButtonProps={{
          onPress: closeModal,
          logger: { id: 'task-type-form-cancel-button-modal' },
        }}
        cancelButtonProps={{
          onPress: closeModal,
          hierarchy: 'tertiary-gray',
          logger: { id: 'task-type-form-cancel-button-modal' },
        }}
        okButtonProps={{
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress: handleSubmit,
          logger: { id: 'task-type-form-ok-button-modal' },
          hierarchy: 'pharmacy-primary',
          text: getText('save'),
        }}
        show={showPMSFormModal}
      >
        <Form methods={methods}>
          <Form.Row>
            <Form.Column style={styles.inputWrapper}>
              <DropdownSelect
                fieldName="integration_type"
                label={getText('pharmacy-software')}
                options={pharmacySoftwares}
              />
            </Form.Column>
          </Form.Row>
          {selectedPMS ? (
            <View>
              <Form.Row>
                <Form.Column style={styles.inputWrapper}>
                  <TextField
                    name="server_address"
                    label={getText('server-address')}
                    rules={{
                      required: getText('server-address-required'),
                    }}
                  />
                </Form.Column>
              </Form.Row>

              {fieldsPerPMS.includes('api_host') ? (
                <Form.Row>
                  <Form.Column style={styles.inputWrapper}>
                    <TextField name="api_host" label={getText('api-host')} />
                  </Form.Column>
                </Form.Row>
              ) : null}
              {fieldsPerPMS.includes('npi') ? (
                <Form.Row>
                  <Form.Column style={styles.inputWrapper}>
                    <TextField name="npi" label={getText('npi')} />
                  </Form.Column>
                </Form.Row>
              ) : null}
              {fieldsPerPMS.includes('api_key') ? (
                <Form.Row>
                  <Form.Column style={styles.inputWrapper}>
                    <TextField
                      name="api_key"
                      label={getText('api-key')}
                      type="password"
                    />
                  </Form.Column>
                </Form.Row>
              ) : null}

              {fieldsPerPMS.includes('auth_user') ? (
                <Form.Row>
                  <Form.Column style={styles.inputWrapper}>
                    <TextField name="auth_user" label={getText('username')} />
                  </Form.Column>
                </Form.Row>
              ) : null}

              {fieldsPerPMS.includes('auth_secret') ? (
                <Form.Row>
                  <Form.Column style={styles.inputWrapper}>
                    <TextField
                      name="auth_secret"
                      label={getText('password')}
                      type="password"
                    />
                  </Form.Column>
                </Form.Row>
              ) : null}

              {fieldsPerPMS.includes('server_port') ? (
                <Form.Row>
                  <Form.Column style={styles.inputWrapper}>
                    <TextField
                      name="server_port"
                      label={getText('server-port')}
                    />
                  </Form.Column>
                </Form.Row>
              ) : null}
            </View>
          ) : null}
        </Form>
      </Modal>
    </>
  );
};

const useStyles = makeStyles(() => ({
  inputWrapper: {
    flexDirection: 'column',
    flexGrow: 1,
    gap: 10,
  },
  container: {
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    width: '50%',
  },
}));

interface PMSIntegrationFormProps {}

export default PMSIntegrationFormModal;
